.Home {
	padding-left: 2%;
	padding-right: 1%;
	display: flex;

	.ProfessionalLeft {
		width: 40%;
		overflow-y: scroll;
		stroke-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.ProfessionalRight {
		width: 60%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		padding-bottom: 50px;

		&::-webkit-scrollbar {
			width: 8px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background: #471cf1;
		}

		.loadingDiv {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.LiveCourseContainerMain {
			margin-top: 15px;
			width: 100%;
			display: flex;
			flex-direction: column;

			.LiveClassContainer {
				display: flex;
				column-gap: 15px;
				overflow: hidden;
				overflow-x: scroll;
				padding-bottom: 10px;

				&::-webkit-scrollbar {
					height: 8px;
				}
				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background: #471cf1;
				}

				.liveClassBox {
					background: #1f1f1f;
					max-width: 350px;
					min-width: 350px;
					height: 150px;
					padding-top: 2%;
					padding-left: 2%;
					padding-right: 5%;
					padding-bottom: 1%;
					cursor: pointer;
				}
			}
		}

		.AllClassContainerMain {
			margin-top: 15px;
			width: 100%;
			display: flex;
			flex-direction: column;

			.AllClassContainer {
				width: 100%;
				height: 180px;
				display: flex;
				overflow: hidden;
				overflow-x: scroll;
				column-gap: 15px;
				padding-bottom: 10px;

				&::-webkit-scrollbar {
					height: 8px;
				}
				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background: #471cf1;
				}

				.allClassesBox {
					max-width: 350px;
					min-width: 350px;
					height: 150px;
					background: #ceacac;
					overflow: hidden;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
