:root {
  --typeface-heading: #fff;
  --backdrop: #0000;
}

body {
  width: 100%;
  background: #000;
  color: var(--typeface-heading);
}

.container {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

/* ::-webkit-scrollbar {
  width: 4px;
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
} */

.AppScroller {
  width: 100vw;
  aspect-ratio: 1728/1117;
  /* height: 100vw * calc(1117/1728); */
  background-image: url("/public/AppScroll.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  margin-top: 100px;
  /* background-attachment: fixed; */
}

@media (max-width: 500px) {
  .AppScroller {
    height: 300px;
    background-size: contain;
  }
}

.promo {
  width: 100%;
  height: 78vh;
  background-image: url("/public/BgPromo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}

@media (max-width: 500px) {
  .promo {
    width: 100%;
    height: auto;
    padding: 0px 22px;
    background-image: url("/public/BgPromo.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: local;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}

.rdw-editor-toolbar {
  background-color: #161616;
  border: none;
}

.rdw-option-wrapper {
  background: #161616;
  color: white;
  border: none;
}

.rdw-option-wrapper img,
svg {
  color: white;
}

.advanced-border {
  border: none;
}
