html {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   overflow: scroll !important;
   height: auto !important;
}

body {
   
   width: 100%;
   height: auto !important;
   overflow: auto !important;
  
}

.container {
    /* background: white;
    color: #010101; */
 }