.timeline-editor-time-area {
    display:none;
    pointer-events: none;
    }
.timeline-editor-time-unit {
        display:none;
        
        }
.timeline-editor-cursor {
       top:0px !important;
       height: calc(100%) !important;
       
              }
.timeline-editor-edit-area .ReactVirtualized__Grid {
    overflow:hidden
}
.timeline-editor-edit-row {
    height:35px !important;
    margin-bottom:5px !important;
    }

.timeline-editor-action-flexible{
    height:35px !important;
    margin-bottom:10px !important;
    }