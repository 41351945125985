.MessagePopupBackground {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(8px);
	z-index: 999999;

	.MessagePopup {
		width: 450px;
		height: 500px;
		position: absolute;
		bottom: 20px;
		right: 100px;
		background: #202020;
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		overflow: hidden;

		.Top {
			display: flex;
			align-items: center;
			gap: 20px;
			padding: 15px 20px 15px 30px;

			img {
				width: 60px;
				height: 60px;
				border-radius: 50%;
			}

			h1 {
				font-size: 20px;
				color: #ffffff;
				margin: 0;
				cursor: pointer;
			}
		}

		.Middle {
			flex: 1;
			padding: 0 20px;
			position: relative;
			display: flex;
			flex-direction: column;
			gap: 20px;
			overflow-y: scroll;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background: #8769ff;
			}

			.My {
				align-self: flex-end;
				min-width: 170px;
				background: #2b2b2b;
				border-radius: 20px 20px 0px 20px;
				padding: 16px 20px 15px 16px;

				P {
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					color: #ffffff;
					margin: 0;
					margin-bottom: 4px;
					white-space: pre-wrap;
				}
				span {
					font-weight: 400;
					font-size: 10px;
					line-height: 15px;
					color: #999999;
				}
			}
			.You {
				align-self: flex-start;
				min-width: 170px;
				background: #471cf1;
				border-radius: 20px 20px 20px 0px;
				padding: 16px 20px 15px 16px;

				P {
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					color: #ffffff;
					margin: 0;
					margin-bottom: 4px;
					white-space: pre-wrap;
				}
				span {
					font-weight: 400;
					font-size: 10px;
					line-height: 15px;
					color: #999999;
				}
			}
		}

		.Bottom {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px 10px 30px 10px;
			gap: 10px;

			input {
				width: 80%;
				border: 1px solid #eee;
				border-radius: 30px;
				font-size: 18px;
				outline: none;
				padding: 10px 25px;
				background: transparent;
				color: #ffffff;
			}

			button {
				border-radius: 50%;
				width: 40px;
				height: 40px;
				font-size: 20px;
				cursor: pointer;
				font-weight: 900;
				background: #471cf1;
				color: #ffffff;
				transition: all 0.2s ease-in-out;

				&:hover {
					background: #20009f;
				}
			}
		}
	}
}
