.Container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 
}

.innerContainer {
  padding: 20px 32px;
  height: auto;
}

/* .innnerContainer p {
    width: 320px;
} */

.Banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: auto;
  padding-top: 24px;
  padding-bottom: 24px;
}

.MusicBanner {
  width: 100%;
  height: 600px;
  background-image: url("/public/BgPromo.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ContentBanner {
  width: 100%;
  height: 600px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ContentBanner p {
  padding-top: 10px;
  width: 340px;
  text-align: left;
}

@media (max-width: 500px) {
  .MusicBanner {
    width: 100%;
    height: auto;
    background-image: url("/public/BgPromo.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .ContentBanner {
    width: 100%;
    height: auto;
    padding: 32px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ContentBanner p {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 10px;
    width: 300px;
    text-align: left;
  }
}
