.hero {
    width: 100%;
    height: 100vh;
    background-image: url('/public/HeroSection.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

 .HeroContaner {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .heroContent h1 {
    padding-top: 42px;
    font-size: 62px;
    line-height: 114%;
    font-weight: bold;
    background: linear-gradient(274deg, #471CF1 8.53%, #FFF 88.82%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
 }

 @media (max-width: 500px) {

    .hero {
        height: 100vh;
        padding-top: 14px;
        padding-bottom: 14px;
    }

.HeroContaner {
    height: 100vh;
}

    .heroContent {
        height: auto;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .heroContent h1 {
        font-size: 30px;
        text-align: center;
    }
 }

 
 @media (max-width: 500px) {
    .heroImage {
        display: none;
    }
 }

 @media (max-width: 480px) { 
    .heroContent {
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
}



}