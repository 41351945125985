
.NavButtonA {
    padding: 8px 30px;
    background: #471CF1;
    /* border: 1px solid white; */
    border-radius: 100px;
   }
   
   .NavButton {
    padding: 8px 30px;
    border: 1px solid white;
    border-radius: 100px;
    cursor: pointer;
   }


   @media (max-width: 500px) {
   

       .NavButton {
      
        font-size: 12px;
       }

       .NavButtonA {
        font-size: 12px;
       }
}

@media (max-width: 320px) {
   

    .NavButton {
   
     font-size: 10px;
    }

    .NavButtonA {
     font-size: 10px;
    }
}
