.ReportPopup {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 20;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
    
	.ReportPopupBox {
        width: 300px;
        backdrop-filter: blur(3px);
		background: #000;
		position: relative;
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		box-shadow: 0 0 8px 1px rgba(255, 255, 255, 0.2);

		.Content {
			flex: 1;
			padding: 10px;
            margin-bottom: 20px;
			h2 {
				text-align: center;
				font-size: 28px;
				margin-bottom: 30px;
			}
			.radioDiv {
				padding-left: 30px;
				margin-bottom: 25px;
				font-size: 20px;
				input {
					margin-right: 20px;
				}
			}
		}

		.ButtonContainer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 20px;
			padding-right: 50px;
			padding-bottom: 20px;

			button {
				background: transparent;
				border: none;
				color: aqua;
			}
		}
	}
}
