@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");



* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
	width: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background:transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: transparent;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: transparent;
  }

.carouse-container {
	overflow: hidden;
	width: 380px;
	/* background: white; */
}

.carousel {
	display: flex;
	width: 380px;
	/* background: white; */
	/* transform: translate(-600px); */
}

.carousel img {
	width: 380px;
}

.current-slide {
	position: absolute;
	bottom: 135px;
	left: 48%;
}

.LeftRightBtns {
	position: absolute;
	/* background: #005eff; */
	width: 380px;
	top: 40%;
	display: flex;
	justify-content: space-between;
}

.arrow-left {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 0;
	background: rgba(255, 255, 255, 0.351);
	backdrop-filter: blur(10px);
}

.arrow-right {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 0;
	background: rgba(255, 255, 255, 0.351);
	backdrop-filter: blur(10px);
}

.Mobile-header {
	display: none;
}

.music-wave-box {
	/* background: linear-gradient(45deg,#0006ff,#a801ff); */
	padding: 5%;
	border-radius: 10px;
	text-align: center;
}

.waves {
	margin-bottom: 10%;
	margin-left: 15%;
}

.music-wave {
	display: block;
	position: relative;
	background: linear-gradient(
		90deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(171, 108, 176, 0.9248074229691877) 29%,
		rgba(0, 212, 255, 1) 100%
	);
	height: 50px;
	width: 8px;
	height: 60px;
	border-radius: 50px;
	margin: 0 8px;
	animation: waves 1s linear infinite;
	-webkit-animation: waves 1s linear infinite;
	/* transform-origin: bottom; */
}

@keyframes waves {
	0% {
		transform: scale(0);
		/* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,0,0,1) 29%, rgba(0,212,255,1) 100%); */
		background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 248, 0, 1) 52%, rgba(0, 212, 255, 1) 100%);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
		/* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(171,108,176,0.9248074229691877) 29%, rgba(0,212,255,1) 100%);  height: 50px; */
	}
}

.music-wave:nth-child(1) {
	animation-delay: 0s !important;
}

.music-wave:nth-child(2) {
	animation-delay: 0.1s !important;
}

.music-wave:nth-child(3) {
	animation-delay: 0.2s;
}

.music-wave:nth-child(4) {
	animation-delay: 0.3s;
}

.music-wave:nth-child(5) {
	animation-delay: 0.4s;
}

.music-wave:nth-child(6) {
	animation-delay: 0.5s;
}

.music-wave:nth-child(7) {
	animation-delay: 0.6s;
}

.music-wave:nth-child(8) {
	animation-delay: 0.7s;
}

.music-wave:nth-child(9) {
	animation-delay: 0.8s;
}

.left-chat-box {
	width: 200.93px;
	height: 50px;
	margin-left: 50%;
	padding-top: 2%;
	color: black;
	background: #ffffff;
	border-radius: 41.2811px 41.2811px 0px 41.2811px;
	margin-top: 1%;
}

.right-chat-box {
	width: 200.93px;
	height: 50px;
	margin-left: -50%;
	padding-top: 2%;
	color: rgb(255, 255, 255);
	background: #3e3aff;
	border-radius: 20.6406px 20.6406px 20.6406px 0px;
	/* transform: matrix(-1, 0, 0, 1, 0, 0); */
	margin-top: 1%;
}

.send-message {
	width: 100%;
	height: 50px;
	border: 1px solid gray;
	border: 10px;
	background: #292929;
	margin-top: 50%;
}

.eye-pass {
	position: relative;
	padding-left: 50px;
}

.eye {
	height: 15px;
	position: absolute;
	top: 45%;
	right: 20%;
}

.eye-register {
	height: 15px;
	position: absolute;
	top: 50%;
	right: 20%;
}

.eye2-register {
	height: 15px;
	position: absolute;
	top: 59%;
	right: 20%;
}

.withdrawl-input {
	background: transparent;
	border: 1px solid gray;
	height: 40px;
	width: 300px;
	border-radius: 10px;
}

.display-flex {
	display: flex;
}

.home-df {
	display: flex;
}

button {
	border: 0;
}

.text-small {
	font-size: 10px;
}

.landing {
	background-color: #000000;
	/* background-image: linear-gradient(225deg, black 0%, #04619f 50%, black 100%); */
	/* background-image: linear-gradient(225deg, #010204 0%, #082f4c 50%, #020305 100%); */
	background-image: linear-gradient(225deg, #010101, #0a1d3b, #063151, #051829, #010101);

	height: 100vh;
	/* width: 700px; */
	background-size: cover;
}

/* .landing-img-1 {
  background-image: url("../images/logoM.png");
  height: 100vh;
  width: 700px;
  background-size: cover;
} */
.landing-img-1 img {
	height: 50px;
}

.landing-img-2 {
	/* background-image: url("../images/Landing2.png"); */
	/* background: black; */
	/* background-color: #000000;
background-image: linear-gradient(147deg, #04619f 0%, black 74%); */
	height: 100vh;
	width: 700px;
	background-size: cover;
}

.GetStarted {
	background-image: url("../images/Rectangle 1 (2).jpeg");
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	/* text-align: center; */
	height: 100vh;
}

.register {
	background-image: url("../images/Rectangle 1 (1).jpg");
	display: flex;
	/* justify-content: center; */
	/* align-items: center; */
	background-repeat: no-repeat;
	background-size: cover;
	/* text-align: center; */
	height: 100vh;
}

.login {
	background-image: url("../images/Rectangle 1 (4).jpg");
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	/* text-align: center; */
	height: 100vh;
}

.forgetpassword {
	background-image: url("../images/Rectangle 17.jpg");
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	/* text-align: center; */
	height: 100vh;
}

.profile-edit-card {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.profile-edit-card input {
	width: 90%;
	height: 49px;
	border: 1px solid gray;
	background: #2b2b2b;
	border-radius: 10px;
	margin-top: 2%;
	margin-bottom: 1%;
	padding: 2%;
	color: rgb(238, 238, 238);
}

.profile-edit-card button {
	width: 40%;
	height: 49px;
	border: 1px solid gray;
	background: #471cf1;
	border-radius: 10px;
	margin-top: 2%;
	margin-bottom: 1%;
	padding: 1%;
}

.edit-pic {
	position: relative;
}

.edit-pic-img {
	position: absolute;
	top: 60px;
	right: 0;
	height: 20px;
}

.card {
	display: flex;
	/* flex-direction: row; */
	justify-content: center;
	align-items: center;
	width: 480px;
	height: fit-content !important;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	left: 350px;
	top: 80px;
	line-height: 30px;
	border-radius: 37.9507px !important;
	text-align: center;
}

.Registercard {
	display: flex;
	/* flex-direction: row; */
	justify-content: center;
	align-items: center;
	width: 480px;
	height: fit-content !important;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	left: 350px;
	top: 50px;
	line-height: 30px;
	border-radius: 37.9507px !important;
	text-align: center;
}

.fPassOTP {
	width: 85% !important;
	padding: 0 60px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.card-text {
		width: 80% !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.card-body {
	/* margin-top: 20px;
  max-width: 420px; */
	margin: 31px 0;
}

.card-title {
	font-weight: 600;
	font-size: 30px;
}

.card-title-small {
	font-weight: 600;
	font-size: 30px;
}

.card-text {
	font-weight: 400;
	font-size: 14px !important;
	margin-bottom: 0%;
}

.card button {
	/* padding: 17.413px 46.4347px; */
	gap: 11.61px;
	width: 80%;
	height: 51.97px;
	border-radius: 11.6087px;
	margin-top: 10px;
	border: 0;
	text-align: center;
}

.card .buttonFull {
	gap: 11.61px;
	width: 80%;
	height: 51.97px;
	border-radius: 11.6087px;
	margin-top: 10px;
	border: 0;
	text-align: center;
}

.loginWithEmail {
	background: #471cf1;
	color: white;
}

.loginWithMoile {
	background: #d1d1d1;
}

.card p {
	margin-top: 1%;
	font-size: 16px;
	color: #666666;
}

.card input {
	width: 85%;
	height: 40.74px;
	background: #ececff;
	border: 1.05716px solid #2622ff;
	border-radius: 10.5716px;
	margin-top: 2%;
	padding: 10px;
	font-size: 14px;
}

#image-input {
	width: 70%;
	height: 50.74px;
	background: #ffffff;
	border: 1.05716px solid #ffffff;
	border-radius: 10.5716px;
	margin-top: 2%;
	padding: 10px;
	font-size: 14px;
}

.card select {
	width: 85%;
	height: 40.74px;
	background: #ececff;
	border: 1.05716px solid #2622ff;
	border-radius: 10.5716px;
	margin-top: 2%;
	padding: 10px;
	font-size: 14px;
}

.card a {
	text-decoration: none;
}

.left {
	max-width: 400px;
	margin-top: 380px;
	margin-left: 4%;
}

.pgNo {
	width: 35px;
	height: 54px;
	left: 72.62px;
	top: 792.17px;

	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 36.2257px;
	line-height: 54px;

	color: rgba(255, 255, 255, 0.6);
}

.leftHeading {
	width: 391px;
	height: 72px;
	left: 72.62px;
	top: 846.17px;

	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 38px;
	line-height: 62px;
	/* identical to box height */

	color: #ffffff;
}

.left-text {
	color: rgba(206, 200, 200, 0.6);
}

.dots {
	margin-top: 10px;
	display: flex;
}

.dot-white {
	width: 60px;
	height: 10px;
	left: 72.62px;
	top: 978.52px;
	margin-right: 3.3px;
	background: #ffffff;
	border-radius: 100px;
}

.dot-purple {
	width: 60px;
	height: 10px;
	left: 72.62px;
	top: 978.52px;

	background: #471cf1;
	border-radius: 100px;
}

.dot-gray {
	width: 31.1px;
	height: 10px;
	left: 135.98px;
	top: 978.52px;
	margin-right: 3px;

	background: rgba(255, 255, 255, 0.2);
	border-radius: 100px;
}

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	padding: 58px 0 0;
	/* Height of navbar */
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
	width: 7%;
	z-index: 600;
	background-color: black;
	/* margin-right: 10000px; */
}

.sidebar ul li {
	background-color: black;
	color: gray;
	margin-top: 70%;
	padding-top: 10px;
}

.sidebar a {
	padding: 10px;
	padding-left: 0px;
}

.sidebar a:hover {
	background-color: black;
	border-right: 7px solid #ffffff;
	border-top-left-radius: 10pc;
	border-bottom-left-radius: 10pc;
	border-top-right-radius: 5pc;
	border-bottom-right-radius: 5pc;
	color: gray;
	margin-top: 60%;
	/* padding: 10px; */
}

.active {
	background-color: black;
	border-right: 7px solid #0084ff;
	border-top-left-radius: 10pc;
	border-bottom-left-radius: 10pc;
	border-top-right-radius: 4pc;
	border-bottom-right-radius: 4pc;
	/* padding: 10px; */
}

.sidebar a img {
	height: 25px;
}

/* .sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
} */
.BasicStyle {
	color: whitesmoke;
	background: linear-gradient(110.09deg, #313131 8.61%, #000000 98.6%);
	margin-left: 7%;
	padding-left: 2%;
	padding-top: 6%;
	min-height: 100vh;
}

.AboutBasicStyle {
	height: 100vh;
	color: whitesmoke;
	background: linear-gradient(110.09deg, #313131 8.61%, #000000 98.6%);
	margin-left: 7%;
	padding-left: 2%;
}

.black-bg {
	background: black !important;
}

.home {
	background: black;
}

.home-left img {
	max-width: 380px;
	/* height: 260px; */
	border-radius: 10px;
	padding-bottom: 2%;
}

/* .share-img{
  width: 380px;
  height: 260px;
  border-radius: 10px;
} */

.home-left .video {
	max-width: 380px;
	height: 320px;
	border-radius: 10px;
	padding-bottom: 2%;
}

.video {
	max-width: 380px;
	height: 260px;
	border-radius: 10px;
	padding-bottom: 2%;
}

.post-img-bg {
	width: 100%;
	overflow: hidden;
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	background: #000000;

	img {
		width: 100%;
		height: 100%;
	}

	video {
		width: 100% !important;
		max-width: 100% !important;
		max-height: 460px;
		border-radius: 10px !important;
		padding: 0 !important;
	}
}

.post-img-bg2 {
	width: 380px;
	padding: 2%;
	padding-bottom: 7%;
	background: rgba(42, 42, 42, 0.2);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	text-align: left;
	/* border: 1px solid rgba(255, 255, 255, 0.3); */
}

.yt_like_btn {
	color: rgba(252, 252, 252, 0.874);
	cursor: pointer;
	background: transparent;
	position: relative;
	border-radius: 10em;
	height: 20px !important;
	user-select: none;
	padding-bottom: 5px;
	text-align: center;
}

.yt_like_btn-active {
	border-radius: 10em;
	height: 20px !important;
	user-select: none;
	padding-bottom: 5px;
	text-align: center;
	cursor: pointer;
	background: transparent;
	color: rgb(255, 255, 255);
	background: transparent;
	text-shadow: 0 0 10px #ff0000, 0 0 20px #ff2626, 0 0 30px #ff0000, 0 0 40px #000000, 0 0 50px #000000;
	/* add multiple shadows with different blur values */
}

.yt_like_btn-active:hover {
	cursor: pointer;
	color: rgb(255, 255, 255);
	text-shadow: 0 0 10px #000000, 0 0 20px #1f0000, 0 0 30px #ff0000, 0 0 40px #000000, 0 0 50px #000000;
	/* add multiple shadows with different blur values */
	animation: flicker 1s infinite alternate;
}

.comment-png {
	text-shadow: 0 0 10px #3300ffaf, 0 0 20px #080029;
}

@keyframes flicker {
	from {
		opacity: 1;
	}

	to {
		opacity: 0.5;
	}
}

.yt_like_btn:hover {
	text-shadow: 0 0 10px #ff2b2b, 0 0 20px #ff2b2b, 0 0 30px #ff2b2b, 0 0 40px #ff2b2b, 0 0 50px #ff2b2b;
	animation: flicker 1s infinite alternate;
}

.sidebarMenu a:active {
	background: white;
}

.like-comment-bar {
	width: 380px;
	height: fit-content;
	/* margin-top: -2%; */
	margin-left: 0%;
	/* background: #000000; */
	backdrop-filter: blur(10px);
	/* border-bottom: 1px solid rgba(128, 128, 128, 0.566); */
	position: sticky;
	text-align: left;
	padding-top: 2%;
	padding-left: 2%;
	padding-right: 4%;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

.userName {
	width: 50%;
	margin-top: 5%;
}

.userName img {
	border-radius: 50% !important;
	height: 40px !important;
}

.like-comment-bar-caption {
	font-size: 13px;
	color: rgb(236, 236, 236);
	margin-bottom: -2%;
}

.like-comment-bar-tag {
	font-size: 13px;
	color: rgb(4, 89, 246);
	margin-top: 1%;
	margin-bottom: -2%;
}

.like-comment-bar img {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid rgb(47, 47, 47);
}

.like-comment-bar-name {
	font-size: 12px;
	color: rgb(211, 211, 211);
}

.like-comment-bar-email {
	font-size: 10px;
	color: gray;
	margin-top: -10%;
}

.share-img {
	height: 50px !important;
	width: 50px !important;
	margin-top: -30%;
	border: 0 !important;
}

.comment-area2 {
	width: 500px;
	margin-left: 10%;
	/* background: #000000; */
	backdrop-filter: blur(30px);
}

.comment-area2 img {
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	border: 1px dotted gray;
	margin-bottom: 2%;
}

.comment-box2 {
	width: 500px;
	height: 40px;
	background: #33333329;
	color: white;
	/* border: 1px solid black; */
	padding: 2%;
	border: 0;
}

.comment-btn2 {
	width: 80px;
	height: 40px;
	/* border-bottom: 1px solid black;
border-right: 1px solid black;
border-top: 1px solid black; */
	/* margin-top: 4%; */
}

.comments2 div {
	margin-top: 2%;
}

.comments2 {
	max-height: 400px;
	overflow-y: scroll;
}

.comments2::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.comment-area {
	width: 380px;
	/* background: #000000; */
	backdrop-filter: blur(30px);
}

.comment-area img {
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	border: 1px dotted gray;
	margin-bottom: 2%;
}

.comment-box {
	width: 400px;
	height: 40px;
	background: #33333329;
	color: white;
	/* border: 1px solid black; */
	padding: 2%;
	border: 0;
}

.comment-btn {
	width: 80px;
	height: 40px;
	/* border-bottom: 1px solid black;
border-right: 1px solid black;
border-top: 1px solid black; */
	/* margin-top: 4%; */
}

.comments div {
	// margin-top: 2%;
}

.comments {
	max-height: 270px;
	overflow-y: scroll;
	padding-bottom: 20px;
}

.comments::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.scroller-100vh {
	max-height: 100vh !important;
	overflow-y: scroll;
}

.scroller-100vh::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.post {
	position: relative;
}

.close-popup-three-dot {
	position: absolute;
	top: -5px;
	right: 0;
	width: 22px;
	height: 22px;
	color: red;
	font-size: 15px;
	font-weight: bold;
	/* background: red; */
	border: 0 !important;
	padding: 2%;
	cursor: pointer;
}
.editContainer {
	display: flex;
	flex-direction: column;
	padding: 20px !important;
	height: 500px !important;
	position: absolute;
	top: 10%;
	border-radius: 10px !important;
	box-shadow: 0 0 10px 8px rgba(255, 255, 255, 0.1);
	h5:first-child {
		margin-bottom: 50px;
	}

	.mediaCont {
		overflow: hidden;
		width: 400px;
		height: 100%;
		border: none;

		img {
			width: 100%;
			height: 100%;
		}
	}
}
.post-three-dot-popup {
	background: black;
	font-size: 12.5px;
	color: gray;
	border: 1px solid gray;
	bottom: 0;
	right: 0px;
	position: absolute;
	width: 200px;
	border-bottom: 0;
	border-radius: 10px;
	z-index: 10;
}

.post-three-dot-popup div {
	display: flex;
	justify-content: center;
	cursor: pointer;
	padding: 6px;
	border-bottom: 0.5px solid gray;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.edit-post-three-dot-popup {
	text-align: left;
	width: 900px;
	background: black;
	color: white;
}

.edit-post-three-dot-popup img {
	height: 200px;
	margin-bottom: 2%;
}

.edit-post-three-dot-popup textarea {
	background: rgba(103, 103, 103, 0.372);
	color: white !important;
	width: 340px;
	height: fit-content;
	padding: 2%;
	padding-bottom: 15%;
	padding-left: 2%;
	/* padding-top: -505px !important; */
	border: 0 !important;
	border-radius: 10px;
}

.home-left {
	background-image: url("../images//blackDesign.jpg");
	width: 900px;
	max-height: 84vh !important;
	overflow-y: scroll;
	margin-left: -2.2%;
	padding: 2%;
}

.share-screen-bg {
	background-image: url("../images//blackDesign.jpg");
	/* backdrop-filter: blur(10px); */
	height: 100vh;
	max-height: 100vh !important;
	width: 65%;
}

.share-screen {
	background-image: url("../images//blackDesign.jpg");
	width: 40%;
	height: 100vh;
	background-repeat: repeat;
	/* background-size: cover; */
	max-height: 100vh !important;
	/* overflow-y: scroll; */
	margin-left: -2.2%;
	padding: 2%;
}

/* .home-left2{
  background-image: url("../images//blackDesign.jpg");
  width: 900px;
  max-height: 100vh !important;
  overflow-y: scroll;
  margin-left: -2.2%;
  padding: 2%;
  margin-top: -7%;
} */
.home-left::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.home-right {
	margin-left: 2%;
}

.home-right2 {
	padding-left: 2%;
	background: black;
}

.home-right-top {
	width: 600px;
	height: fit-content;
	padding-top: 3%;
	padding-top: 3%;
	padding-left: 5%;
	background: rgba(62, 62, 62, 0.4);
	border-radius: 15.9173px;
	position: relative;
}

.home-right-top p {
	color: #999999;
	font-size: 15px;
}

.home-right-top input {
	width: 510.92px;
	height: 50.61px;
	background: rgba(115, 115, 115, 0.25);
	border-radius: 6.70096px;
	border: 0;
	color: white;
	padding-left: 2%;
}

.home-right-top textarea {
	width: 510.92px;
	height: 50.61px;
	background: rgba(115, 115, 115, 0.25);
	border-radius: 6.70096px;
	border: 0;
	color: white;
	padding-left: 2%;
}

.home-right-top button {
	margin: 2%;
	color: #ffffffb5;
	border: 1px solid #ffffff43;
	width: 100.51px;
	height: 35px;
	max-height: 35px;
	overflow: hidden;
	/* background: #471CF1; */
	border-radius: 67.0096px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

.home-right-top .upload-btn-wrapper {
	margin: 2%;
	color: #525050;
	/* border: 1px solid #525050; */
	width: 100.51px;
	height: 35px;
	max-height: 35px;
	overflow: hidden;
	/* background: #471CF1; */
	border-radius: 67.0096px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

.home-right-top button input {
	margin-top: 35%;
}

.upload-content {
	background: #471cf1 !important;
	color: white !important;
	width: 125.51px !important;
}

.home-right-mid-blocks {
	background: #1f1f1f;
	max-width: 48%;
	min-width: 38%;
	padding-top: 2%;
	padding-left: 2%;
	padding-right: 5%;
	padding-bottom: 1%;
	margin-left: 1%;
}

.home-right-mid-blocks2 {
	background: #1f1f1f;
	max-width: 48%;
	min-width: 38%;
	padding-top: 1%;
	padding-left: 1%;
	padding-right: 2%;
	padding-bottom: 1%;
	margin-left: 1%;
	border-radius: 10px;
}

.home-right-mid-blocks2 p {
	color: #999999;
	font-size: 12px;
}

.home-right-mid-blocks2 span {
	color: #999999;
	font-size: 12px;
	padding-bottom: 4%;
	padding-top: 4%;
}

.home-right-mid-blocks p {
	color: #999999;
	font-size: 12px;
}

.home-right-mid-blocks span {
	color: #999999;
	font-size: 12px;
	padding-bottom: 4%;
	padding-top: 4%;
}

.home-right-bottom {
	max-height: 350px;
	overflow-y: scroll;
}

.home-right-bottom::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.home-right-bottom h5 {
	position: sticky;
	top: 10px;
	z-index: 1;
}

.home-right-bottom img {
	height: 140px;
	width: 140px;
	/* max-height: 140px;
  min-height: 140px; */
	margin: 1%;
}

.popup {
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	backdrop-filter: blur(10px);
	margin-top: -100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1920px;
	height: 100vh;
}

.popup2 {
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	backdrop-filter: blur(10px);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1315px;
	max-width: 1315px;
	height: 185vh;
	z-index: 11;
}

.album-popup {
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	right: 0;
	backdrop-filter: blur(10px);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 500px;
	height: 113vh;
	z-index: 11;
}

.album-popup-img {
	width: 100%;
	height: 200px;
	/* margin-top: -602px; */
}

.popup-box2 {
	margin-top: 40% !important;
	margin-left: 10% !important;
	width: 1300px;
	height: 400px;
	color: white;
	position: relative;
}

.popup-box3 {
	margin-top: 0% !important;
	margin-left: 10% !important;
	margin-right: 10% !important;
	width: 100%;
	height: 110vh;
	color: white;
	/* background-color: red; */
	position: relative;
}

.other-albums {
	width: 100%;
	height: 59vh;
	overflow-y: scroll;
	color: white;
	/* background-color: rgb(2, 0, 0) */
}

.other-albums::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.popup-box2 button {
	margin: 1%;
}

.popup-box {
	width: 700px;
	height: 400px;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.popup-box-green {
	width: 700px;
	height: 400px;
	background: #1f4926;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.close-popup {
	position: absolute;
	top: 0;
	right: 0;

}

.close-popup-edit-post {
	position: absolute;
	top: 0;
	right: 0px;
	padding-left: 1.5%;
	padding-top: 1%;
	color: rgb(187, 187, 187) !important;
	background: rgba(61, 61, 61, 0.332) !important;
	height: 40px;
	width: 40px;
	border-radius: 50%;
}

.close-popup-album {
	position: absolute;
	top: 50;
	left: 0;
	height: 100px;
	width: 30px;
	border-top-right-radius: 10pc;
	border-bottom-right-radius: 10pc;
}

.ring {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: none;
	animation: ring 1s linear infinite;
}

@keyframes ring {
	0% {
		transform: rotate(0deg);
		box-shadow: 1px 1px 1px #ffffff;
	}

	50% {
		transform: rotate(180deg);
		box-shadow: 1px 1px 1px #ffffff;
	}

	100% {
		transform: rotate(360deg);
		box-shadow: 1px 1px 1px #ffffff;
	}
}

.ring2 {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: none;
	animation: ring2 1s linear infinite;
}

@keyframes ring2 {
	0% {
		transform: rotate(0deg);
		box-shadow: 1px 1px 1px #0015ff;
	}

	50% {
		transform: rotate(180deg);
		box-shadow: 1px 1px 1px #005eff;
	}

	100% {
		transform: rotate(360deg);
		box-shadow: 1px 1px 1px #05009d;
	}
}

.search {
	width: 600px;
	height: 68px;
	padding: 4%;
	border: 0;
	background: rgba(84, 84, 84, 0.63);
	border-radius: 100px;
}

.instructorHead {
	background-image: url("../images//Rectangle 80 (2).png");
	height: 55vh;
	margin-top: -7%;
	margin-left: -4%;
	padding-left: 5%;
	padding-top: 1%;
	justify-content: space-between;
}

.instructorHead button {
	width: 117px;
	height: 35px;
	left: 1078.3px;
	top: 292px;
	margin-top: 40vh;
	margin-right: 5%;
	background: #471cf1;
	border-radius: 10px;
}

.coursePurache {
	background-image: url("../images//Rectangle 80 (3).png");
	height: 55vh;
	margin-top: -7%;
	margin-left: -4%;
	padding-left: 5%;
	padding-top: 1%;
	justify-content: space-between;
}

.coursePurache button {
	background: #2f2f2f;
	border-radius: 8px;
	margin-left: 1%;
	color: white;
	width: 150px;
}

.coursePurache .paid {
	background: #471cf1;
	border-radius: 10px;
	color: white;
}

.account {
	background-image: url("../images//Rectangle 110.png");
	height: 30vh;
	/* width: 100%; */
	margin-top: -7%;
	margin-left: -4%;
	padding-left: 5%;
	background-size: cover;
	background-repeat: no-repeat;
}

.account-right {
	background-image: url("../images//blackDesign.jpg");
	width: 500px;
	max-height: 100vh !important;
	overflow-y: scroll;
}

.account-right img {
	max-width: 480px;
	/* height: 260px; */
	border-radius: 10px;
	padding-bottom: 2%;
}

.account-right video {
	max-width: 480px;
	height: 260px;
	border-radius: 10px;
	padding-bottom: 2%;
}

.account-right::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.accountBtn {
	width: fit-content;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	padding: 1%;
	background: transparent;
	color: #666666;
	border: 1px solid #666666;
	margin: 1%;
}

.gender-selector input[type="radio"] {
	height: 15px;
}

.TopPlayer {
	color: white;
	width: 130px;
	height: 24px;
	border: 0;
	background: #471cf1;
	border-radius: 100px;
	margin-right: 1%;
}

.primary-color {
	color: #471cf1;
}

.primary-bg {
	background: #471cf1;
	color: white;
}

.abt-heading {
	margin-top: -5%;
}

.about {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	padding-bottom: 20px;
	scrollbar-width: none;
	padding-right: 10px;
	padding-top: 20px;
	padding-left: 20px;

	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
		background-color: #471cf1;
	}
}

.about img {
	margin-left: 1%;
	/* margin-top: -5%; */
	width: 95%;
}

.about p {
	margin-top: 1%;
}

.about hr {
	max-width: 80px;
	margin-top: -0.1%;
}

.album-page {
	height: 100vh;
}

.album-search {
	height: 20px !important;
	width: 20px !important;
	margin-right: 10%;
}

.album-search:hover {
	height: 25px !important;
	width: 25px !important;
	margin-right: 10%;
	cursor: pointer;
}

.socialImages img {
	height: 20px;
	width: 20px;
	margin-left: 2%;
	cursor: pointer;
}

.supportColumns {
	background: #232323;
	padding-left: 3%;
	padding-right: 6%;
	padding-top: 1%;
	padding-bottom: 1px;
}

.supportColumns a {
	color: #8769ff !important;
}

.support input {
	background: #202020;
	width: 370px;
	height: 62px;
	left: 843.11px;
	top: 285.24px;
	margin-bottom: 2%;
	margin-top: 2%;
	border: 1px solid #a6a6a6;
	border-radius: 100px;
	padding: 4%;
}

.support textarea {
	margin-top: 2%;
	background: #202020;
	border: 1px solid #a6a6a6;
	width: 370px;
	border-radius: 20px;
	padding: 4%;
}

.raiseTicketBtn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 15px 60px;
	gap: 10px;
	border: 0;
	width: 598px;
	height: 76px;
	left: 192px;
	top: 731px;
	color: white;
	background: #471cf1;
}

.supportSubmitBtn {
	color: white;
	padding: 15px 60px;
	gap: 10px;
	// width: 170px;
	height: 51px;
	background: #471cf1;
	border-radius: 10px;
	border: 0;
	transition: all 0.2s ease-in-out;

	&:hover {
		background: #1c008e;
	}
}

.history {
	max-height: 400px;
	overflow-y: scroll;
}

.album {
	max-height: 400px;
	overflow-y: scroll;
	margin-bottom: 5%;
}

.album::-webkit-scrollbar {
	border: 0;
	background-color: transparent;
}

.album h5 {
	position: sticky;
	top: 10px;
}

.album img {
	height: 105px;
	margin: 1%;
}

.album-bottom {
	justify-content: space-between;
	align-items: center;
	background: #471cf1;
	border-radius: 100px;
	padding-left: 15px;
	padding-right: 15px;

	width: 25%;
}

.album-bottom img {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	cursor: pointer;
}

.album-bottom p {
	font-size: 10px;
}

/* Profession Css Get Started from here */

.Professional-GetStarted {
	background-image: url("../images//ProffesionalGetstartedBg.png");
	background-repeat: no-repeat;
	background-size: cover;
	color: white;
	height: 100vh;
}

.Professional-GetStarted .header {
	height: 50px;
	display: flex;
	justify-content: space-between !important;
	width: 90%;
	margin-left: 5%;
	padding-top: 4%;
}

.Professional-GetStarted .header .display-flex div {
	margin-left: 5%;
}

.Professional-GetStarted .header .display-flex img {
	height: 50px;
}

.Professional-GetStarted p {
	font-size: 11px;
	margin-top: -5%;
}

.Professional-GetStarted .display-flex {
	/* background-color: wheat; */
	width: 250px;
}

.Professional-GetStarted h5 {
	margin-top: 10%;
}

.Professional-GetStarted h1 {
	margin-top: 15%;
	max-width: 650px;
	margin-left: 10%;
	font-size: 60px;
}

.professional-login {
	background-image: url("../images//ProffesionalSignin.png");
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	/* text-align: center; */
	height: 100vh;
}

.professional-card {
	background: #2c2c2c !important;
}

.professional-card-title {
	color: white !important;
}

.inpt {
	background: #252525 !important;
}

.balance {
	width: 680px;
	height: 276.79px;
	left: 170px;
	top: 233.21px;
	padding: 4%;
	background: #252525;
	border-radius: 40px;
	margin: 2%;
}

.acc {
	margin-left: 1%;
	background-image: url("../images/Rectangle 129.png");
	width: 680px;
	height: 276.79px;
	border-radius: 40px;
	padding: 4%;
	margin: 2%;
}

.tansaction {
	width: 99%;
	height: 61px;
	left: 169px;
	top: 581px;
	padding: 1.5%;
	background: #282828;
}

.payouts {
	max-height: 220px;
	overflow-y: scroll;
}

.payouts::-webkit-scrollbar {
	border: 0;
	background-color: transparent;
}

.payout {
	width: 500px;
	justify-content: space-between;
}

.payoutImg {
	background-color: #2622ff;
	height: 50px;
	width: 50px;
	border-radius: 50%;
}

.payout p {
	margin-top: 2%;
}

.Modal input {
	width: 420px;
	height: 50px;
	left: 675.3px;
	border: 0.862516px solid #252525;
	border-radius: 8.62516px;
	padding: 5%;
	margin-top: 1%;
}

.Modal textarea {
	width: 420px;
	left: 675.3px;
	border: 0.862516px solid #252525;
	border-radius: 8.62516px;
	padding: 5%;
	margin-top: 1%;
}

.blur {
	filter: blur(8px);
	-webkit-filter: blur(8px);
}

.BasicStyle-professional {
	color: whitesmoke;
	background: linear-gradient(110.09deg, #000000 8.61%, #000000 98.6%);
	padding-left: 2%;
	padding-right: 2%;
	padding-top: 6%;
}

.BasicStyle-professional::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.threeDotBox {
	/* display: flex; */
	padding: 60px 300px 60px 30px;
	width: 50px;
	height: 5px;
	background: #282828;
	border-bottom: 1px solid #2e2e2e;
	border-radius: 10px;
	color: white;
	font-size: 20px;
}

.threeDotBox div {
	margin-top: -9px;
	margin-left: 5px;
}

.threeDotsComponent {
	margin-left: -15%;
}

.threeDotsComponent div {
	margin-top: 1%;
}

.threeDotsComponent div div {
	margin-left: 1%;
}

.professional-header {
	position: sticky;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left : 2%;
	padding-right : 2%;
	padding-top:1%;
	padding-bottom:0%;
	z-index: 1111;
}

.professional-header img {
	height: 50px;
}

.professional-header .display-flex div {
	width: 200px !important;
}

.professional-header p {
	margin-top: -5%;
	font-size: 12px;
	margin-left: 7%;
}

.professional-header h5 {
	margin-top: 2%;
	margin-left: 7%;
}

.three-dot-img {
	height: 30px !important;
}

/* TODO:  start*/
.landing-img-1 {
	flex-basis: 35%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.landing-img-2 {
	flex-basis: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;

	.AppPlayStoreButtons {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.button {
			width: 230px;
			height: 70px;
			padding: 10px 20px;
			display: flex;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			background: #000;
			border-radius: 10px;

			img {
				width: 200px;
				height: 70px;
				border-radius: 0%;
			}
			
			svg{
				width: 50px;
				height: 50px;
			}

			div {
				display: flex;
				// align-items: center;
				flex-direction: column;
				gap: 5px;

				h5 {
					color: #fff;
					margin: 0;
					font-size: 15px;
				}
				
				h4 {
					color: #fff;
					margin: 0;
					font-size: 20px;
				}
			}
		}
	}
}

/* TODO:  start*/
.landing h1 {
	/* margin-top: 25%;
  margin-left: 35%;
  height: 400px;
  width: 400px;
  border-radius: 50%; */
	text-shadow: 0 0 6px rgba(202, 228, 225, 0.92), 0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52),
		0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78), 0 0 54px rgba(30, 132, 242, 0.92);
	/* animation: flicker 1s infinite alternate; */
}

.landing img {
	/* margin-top: -80%;
  margin-left: 15%; */
	height: 400px;
	width: 400px;
	border-radius: 50%;
	text-shadow: 0 0 6px rgba(202, 228, 225, 0.92), 0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52),
		0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78), 0 0 54px rgba(30, 132, 242, 0.92);
	/* animation: flicker 1s infinite alternate; */
}

.landing-content {
	color: white;
	max-width: 700px;

	h3 {
		font-size: 40px;
	}
}

.landing-content-btn-container {
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	margin-bottom: 10px;
}

.landing-content button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;
	width: 161px;
	height: 51px;
	left: 107px;
	top: 477px;
	color: #fff;
	background: #2e2e2e;
	transition: all 0.1s ease-in-out;
	border-radius: 10px;
}

.landing-content button:hover {
	background: #471cf1;
}

.LandingAboutUs {
	float: right;
}

.landing-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 20px;
	gap: 20px;
	width: 183px;
	height: 64px;
	left: 107px;
	top: 652px;

	background: #471cf1;
	border-radius: 10px;
}

.landing-btn:hover {
	background: #3612c9;
	cursor: pointer;
}

.close-popup {
	color: red !important;
	background: rgb(36, 17, 17) !important;
	height: 50px;
	width: 50px;
	border-radius: 100%;
}

.slider-left2right {
	max-width: 1200px;
	overflow-x: scroll;
}

landing-img-1.slider-left2right::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.slider-left2right2 {
	max-width: 1300px;
	overflow-x: scroll;
}

.slider-left2right2::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.justify-space {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.justify-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.justify-arrond {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.round-img {
	height: 120px;
	width: 120px;
	border-radius: 50%;
	border: 1px dotted gray;
}

.notification-box {
	max-height: 900px !important;
	/* height: 900px !important; */
	background: #2b2b2b;
	overflow-y: scroll;
	margin-bottom: -12%;
}

.notification-box::-webkit-scrollbar {
	border: 0;
	background: transparent;
}

.notifications {
	width: 1048px;
	height: 70px;
	border-bottom: 1px solid #767676;
}

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	cursor: pointer;
}

.upload-btn-wrapper:hover {
	background: rgb(20, 101, 168);
	color: white !important;
	cursor: pointer;
}

.home-u-btn:hover {
	background: rgb(20, 101, 168);
}

/* .upload-btn-wrapper input[type=file]:hover{
  background: rgb(21, 77, 123);
  color: white !important;
}
.upload-btn-wrapper .btn:hover{
  background: rgb(21, 77, 123);
  color: white !important;
} */
.btn {
	border: 2px solid gray;
	color: gray;
	background-color: white;
	padding: 8px 20px;
	border-radius: 8px;
	font-size: 20px;
	font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}

.ImageuploadContainer {
	width: 120px;
	height: 30px;
	cursor: pointer;
	position: relative;
	/* background: url("../images/uploadContent.png"); */
	background-repeat: no-repeat;
	background-size: cover;
}

.uploadText {
	position: absolute;
	top: 238px;
	left: 54%;
	color: grey;
	font-size: 12px;
}

.Imageupload {
	cursor: pointer;
	width: 120px;
	height: 120px;
	opacity: 0;
}

.remember-me {
	margin-left: -10%;
}

.check-box-remember {
	margin-left: 5%;
}

.create-post-mobile {
	display: none;
}

@media only screen and (max-width: 992px) {
	.BasicStyle {
		margin-left: 0%;
		padding-left: 2%;
		padding-top: 0%;
	}

	.landing-img-1 {
		background-image: url("../images/Landing1.png");
		height: 100vh;
		width: 50%;
		/* background-size: cover; */
	}

	.landing-img-2 {
		background-image: url("../images/Landing2.png");
		height: 100vh;
		width: 50%;
		/* background-size: cover; */
	}

	.landing-content {
		color: white;
		max-width: 400px;
		margin-top: 400px;
		margin-left: 7%;
	}

	.landing-content h3 {
		color: white;
		max-width: 150px;
		margin-top: 100px;
		margin-left: 7%;
	}

	.landing-btn {
		margin-top: 25%;
		width: 90%;
	}

	.GetStarted {
		display: block;
		max-height: 100vh;
		overflow: scroll;
		/* max-width: 100%; */
	}

	.login {
		display: block;
		max-height: 100vh;
		overflow: scroll;
	}

	.register {
		display: block;
		max-height: 100vh;
		overflow: scroll;
	}

	.forgetpassword {
		display: block;
		max-height: 100vh;
		overflow: scroll;
	}

	.left {
		max-width: 400px;
		margin-top: 80px;
		margin-left: 4%;
	}

	.card {
		margin-left: -80%;
		width: 90%;
		height: 50%;
	}

	.profile-edit-card {
		display: block;
	}

	.profile-edit-card input {
		width: 190%;
		height: 49px;
		border: 1px solid gray;
		background: #2b2b2b;
		border-radius: 10px;
		margin-top: 2%;
		margin-bottom: 1%;
		padding: 2%;
	}

	.profile-edit-card div input {
		width: 190%;
		height: 49px;
		border: 1px solid gray;
		background: #2b2b2b;
		border-radius: 10px;
		margin-top: 6%;
		margin-bottom: 6%;
		padding: 2%;
	}

	.profile-edit-card button {
		width: 80%;
		height: 49px;
		border: 1px solid gray;
		background: #471cf1;
		border-radius: 10px;
		margin-top: 6%;
		margin-bottom: 6%;
		padding: 1%;
	}

	.account {
		height: 10vh;
	}

	.home-right2 {
		display: none;
	}

	.post-img-bg {
		width: 100%;
	}

	.post-img-bg2 {
		width: 100%;
	}

	.home-left img {
		max-width: 100%;
		height: auto;
		border-radius: 10px;
		padding-bottom: 2%;
	}

	.home-left .video {
		max-width: 100%;
		height: auto;
		border-radius: 10px;
		padding-bottom: 2%;
	}

	.like-comment-bar {
		width: 100%;
	}

	.comment-area {
		width: 100%;
	}

	.comment-area img {
		border-radius: 50%;
	}

	.Mobile-header {
		display: block;
		padding: 2%;
		background: black;
	}

	.create-post-mobile {
		display: block;
		position: absolute;
		bottom: 2%;
		z-index: 111;
		width: 90%;
		margin-left: 5%;
		backdrop-filter: blur(30px);
		padding: 2%;
		border-radius: 10px;
		font-size: 12px;
		color: white;
	}

	.create-post-mobile-btn {
		border: 1px solid lightblue;
		padding-left: 5%;
		padding-right: 5%;
		padding-top: 2%;
		padding-bottom: 2%;
		border-radius: 10pc;
	}

	.create-post-mobile-input {
		border: 0;
		background: rgba(128, 128, 128, 0.264);
		color: white;
		padding: 2%;
		width: 75%;
	}

	.upload-btn-wrapper {
		height: 30px;
		width: 70px;
	}

	.upload-btn-wrapper button {
		color: white;
		margin-left: -40%;
		margin-top: -25%;
		font-size: 12px;
	}

	.remember-me {
		margin-left: -10%;
	}

	.check-box-remember {
		margin-left: 5%;
	}

	.eye {
		height: 15px;
		position: absolute;
		top: 40%;
		right: 22%;
	}

	.eye-register {
		height: 15px;
		position: absolute;
		top: 48%;
		right: 22%;
	}

	.eye2-register {
		height: 15px;
		position: absolute;
		top: 57%;
		right: 22%;
	}
}


