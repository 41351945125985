.header {
    background: #202020;
    width: 100%;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
}

.NavMenu {
    display: flex;
    gap: 18px;
}

.NavMenu li {
    color: #959595;
    padding: 8px 22px;
}

.NavMenu li:hover {
    color: #8161FF;
    /* color: #471CF1; */
}

.NavButton {
   height:50px;
 padding: 8px 30px;
 border: 1px solid white;
 border-radius: 100px;
 cursor: pointer;
 display:flex;
 justify-content: center;
 align-items: center;
}

.NavButton:hover {
    border: none;
    background: #8161ff;
}

@media (max-width: 480px) {
    .NavButton {
        padding: 8px 15px;
        /* border: none; */
       
    }
}

.logonew {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    box-shadow: "0 0 10px 3px rgba(255,255,255,.3)",
   
   
}

@media (max-width: 480px) {
    .logonew {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        box-shadow: "0 0 10px 3px rgba(255,255,255,.3)",
       
       
    }
}
