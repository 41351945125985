.Backdrop {
    width: 100%;
    height: 100vh;
    background-image: url('/public/SecondSection.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

@media (max-width: 500px) {
    .Backdrop {
        /* background-image: url('/SecondBBG.png'); */
         background-image: url('/public/SecondBBG.png');
    }
}

.NavButtonA {
    padding: 8px 30px;
    background: #471CF1;
    /* border: 1px solid white; */
    border-radius: 100px;
   }
   

   .NavButton {
    padding: 8px 30px;
    border: 1px solid white;
    border-radius: 100px;
    cursor: pointer;
   }


   .Container {
    padding-left: 58px;
    padding-top: 48px;
   }


   @media (max-width: 500px) {
    .Container {
        padding-left: 18px;
        padding-top: 48px;
        padding-right: 18px;
       }

       .NavButton {
        padding: 8px 30px;
        border: 1px solid white;
        border-radius: 100px;
        cursor: pointer;
        font-size: 12px;
       }

       .NavButtonA {
        font-size: 12px;
       }
}

@media (max-width: 320px) {
   

    .NavButton {
   
     font-size: 10px;
    }

    .NavButtonA {
     font-size: 10px;
    }
}
