.vis-labelset .vis-label {
  border: 0px solid #f200c9;

  border-bottom: 0px solid #00c3f8;
}


.vis-timeline {
  border: 0px solid white;
  border-radius: 0px;
  height: 160px !important;
  overflow: hidden !important;
}

.vis-time-axis {
  color: rgb(188, 0, 0);
  opacity: 0.6;
}

.vis-pannel.vis-center {
  overflow-y:hidden !important;
  touch-action: pan-x !important;
}





.vis-foreground .vis-group {
  border-bottom: 0px solid #bfbfbf;
  height: 45px !important;
 
}






.vis-item{
  height:45px !important;
  top:10px !important;
}

.vis-item.vis-range {
  border-radius: 0px;
  
}


.video {
  background-color: #1C2733;
  color: #F7F7F7;
  border-color: transparent;
  border-radius: 0;
}

.text {
  background-color: #077488;
  color: #F7F7F7;
  border-color: transparent;
  border-radius: 0;
  margin-top: 16px;
}

.image {
  background-color: #078870;
  color: #F7F7F7;
  border-color: transparent;
  border-radius: 0;
}

.audio {
  background-color: #160D21;
  color: #F7F7F7;
  border-color: transparent;
  border-radius: 0;
  margin-top: 8px;
}
.video12 {
  background-color: #1C2733;
  color: #F7F7F7;
  border-color: transparent;
  border-radius: 0;
}






.vis-time-axis.vis-background {
  display: none;
}


.vis-background {
  background-color: transparent;;

}

.currenttime {
  background: rgb(188, 0, 0);
  
}

.vis-time-axis .vis-text {

  color: #3C4369;
  display: none;
  /*left: -12px*/
}
.vis-panel.vis-top {
  display: none;
}

.vis-panel.vis-bottom,
.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top {
  border: 0px solid white;
}

.vis-drag-left {
  background-color: #FFFFFF10;
  transform: scale(0.2, 0.8);
  border-radius: 2px;



}

.vis-drag-right {
  background-color: #FFFFFF10;
  transform: scale(0.2, 0.8);
  border-radius: 2px;  

}