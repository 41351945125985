.belowTopBtn {
	height: 84px;
	background: #2b2b2b;
	border-radius: 15px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	display: flex;
	align-items: center;
	column-gap: 20px;
	margin: 10px 100px 10px 0;

	h3 {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.0025em;
		color: #ffffff;

		&:first-child {
			cursor: default;
		}
		cursor: pointer;

		span {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0em;
			color: #666666;
		}
	}
}

.albumContainerImage {
	.courseItem {
		width: 100%;
		height: 250px;
		overflow: hidden;
		cursor: pointer;
		border-radius: 10px;
		position: relative;
		img {
			width: 100%;
			height: 100%;
		}

		.details {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 2% 2% 0 2%;
			background: rgba(0, 0, 0, 0.6);
			overflow: hidden;
			display: flex;
			flex-direction: column;

			p {
				display: block;
				overflow: hidden;
			}
		}
	}
}

.FollowBtn{
	display: flex;
	align-items: center;
	background: #471cf1;
	color: #fff;
	padding: 5px 45px;
	font-size: 18px;
	border-radius: 10px;
}
.buttonContainer{
	flex-wrap: wrap;
}

.albumBox{
	margin: 0 auto;
	width:70%;
	height: 200px;
	overflow: hidden;
	border-radius: 10px;
	margin-bottom: 20px;
	position: relative;
	cursor: pointer;

	img{
		width: 100%;
		height: 100%;
	}

	div{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		background: rgba(0, 0, 0, 0.6);
		padding: 10px;
		overflow: hidden;
	}
}